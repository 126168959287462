import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useState, useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'

import { useUser } from '@hooks/user'

const UserDropdown = dynamic(
  async () => (await import('./UserDropdown')).UserDropdown,
  { ssr: false }
)

const mobileNavVariants = {
  hidden: { y: -300, x: 0 },
  visible: { y: 0, x: 0 }
}

export function Header() {
  const { isLoggedIn, logout } = useUser()
  const router = useRouter()
  const menuRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (!menuRef.current?.contains(event.target as Node)) {
        setShowMenu(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  useEffect(() => {
    setShowMenu(false) // Close menu when the route changes
  }, [router.pathname])

  return (
    <>
      <header className="relative bg-white md:py-4 py-2 md:px-12 px-4 mx-auto flex md:justify-between items-center md:border-0 border border-gray-300 z-20">
        <div className="md:flex hidden justify-between items-center w-full">
          <Link href="/" passHref>
            <a>
              <img
                src="/images/thumbtack/combination_logo.png"
                className="w-48 md:inline hidden"
                alt="Thumbtack logo"
              />
            </a>
          </Link>
          <nav className="md:flex hidden items-center space-x-12">
            <UserDropdown />
          </nav>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex">
          <button
            onClick={() => setShowMenu(!showMenu)}
            className="rounded-lg text-gray-600 flex items-center"
            aria-label="menu"
          >
            <img
              src="/images/thumbtack/logo_blue.png"
              className="w-10 mr-2"
              alt="Thumbtack logo"
            />
            {showMenu ? (
              <ChevronUpIcon width={18} height={18} />
            ) : (
              <ChevronDownIcon width={18} height={18} />
            )}
          </button>
        </div>
      </header>

      {/* Mobile Menu */}
      <AnimatePresence>
        {showMenu && (
          <motion.nav
            variants={mobileNavVariants}
            ref={menuRef}
            initial="hidden"
            animate="visible"
            exit="hidden"
            className="absolute top-16 left-0 shadow-xl rounded-lg bg-white w-full z-10"
          >
            <div className="flex flex-col items-start">
              <NavLink href="/" label="Home" setShowMenu={setShowMenu} />
              {isLoggedIn ? (
                <>
                  <NavLink
                    href="/repair-estimate"
                    label="My Properties"
                    setShowMenu={setShowMenu}
                  />
                  <NavLink
                    href="/settings"
                    label="Settings"
                    setShowMenu={setShowMenu}
                  />
                  <button
                    onClick={() => {
                      logout.mutate()
                      setShowMenu(false)
                    }}
                    className="font-bold text-xl font-heading p-4 hover:bg-gray-200 hover:text-brand w-full text-left"
                  >
                    Log out
                  </button>
                </>
              ) : (
                <>
                  <NavLink
                    href="/signup"
                    label="Sign Up"
                    setShowMenu={setShowMenu}
                  />
                  <NavLink
                    href="/login"
                    label="Log In"
                    setShowMenu={setShowMenu}
                  />
                </>
              )}
            </div>
          </motion.nav>
        )}
      </AnimatePresence>
    </>
  )
}

function NavLink({ href, label, setShowMenu }) {
  return (
    <Link href={href} passHref>
      <a
        className="font-bold text-xl font-heading p-4 hover:bg-gray-200 hover:text-brand w-full"
        onClick={() => setShowMenu(false)}
      >
        {label}
      </a>
    </Link>
  )
}
