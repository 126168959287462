import has from 'lodash/has'
import { InputHTMLAttributes } from 'react'
import { ErrorMessage, FieldProps } from 'formik'

import styles from './Input.module.css'
import { InvalidMessage } from '../InvalidMessage'

interface InputProps
  extends Partial<FieldProps>,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'form'> {
  showErrors?: boolean
}

export function Input(props: InputProps) {
  const { showErrors = true, field, form, className, ...rest } = props

  let constructedClassName = styles.input
  if (
    has(form?.touched ?? {}, field?.name ?? '') &&
    has(form?.errors ?? {}, field?.name ?? '')
  ) {
    constructedClassName += ` ${styles.error}`
  }
  if (className) {
    constructedClassName += ` ${className}`
  }

  return (
    <>
      <input className={constructedClassName} {...field} {...rest} />
      {showErrors && form && (
        <ErrorMessage component={InvalidMessage} name={field?.name ?? ''} />
      )}
    </>
  )
}
