import styles from './Spinner.module.css'

export function Spinner() {
  return (
    <div
      data-testid="loadingSpinner"
      className={`animate-spin ${styles.spinner}`}
    />
  )
}
