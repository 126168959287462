import { useState, FormEvent } from 'react'
import { useUser } from '@hooks/user'

import { NextSeo } from 'next-seo'
import { Header } from '@components/Header'
import { Footer } from '@components/Footer'
import { Button } from '@components/UI/Button'
import { Label } from '@components/UI/Form/Label'
import { Input } from '@components/UI/Form/Input'
import { Spinner } from '@components/UI/Spinner'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { CheckCircleIcon } from '@heroicons/react/outline'

export default function Login() {
  const { login } = useUser()
  const { query, push } = useRouter()
  const [error, setError] = useState('')

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()

    async function attemptLogin(email: string, password: string) {
      try {
        await login.mutateAsync({ email, password })

        const reportId =
          localStorage.getItem('current-report') ??
          localStorage.getItem('default-report')
        if (reportId) {
          void push(`/repair-estimate/${reportId}`)
        } else {
          void push('/repair-estimate')
        }
      } catch (error) {
        if (error.response.status === 404) {
          setError('Your email or password is incorrect')
        }
      }
    }

    const data = new FormData(e.currentTarget)
    const email = data.get('email') as string
    const password = data.get('password') as string

    void attemptLogin(email, password)
  }

  return (
    <>
      <NextSeo
        title="Log In"
        description="New to Majordomo? Local and accurate Home inspection repair estimates in 24-hours or less. 24-hour Instant chat, log-in Create an Account, Forgot Password"
      />
      <Header />

      <main className="md:pt-8 pt-12 sm:px-0 px-8 container mx-auto">
        <div className="flex md:flex-row flex-col justify-between items-center lg:pb-36 pb-12">
          <div className="flex flex-col md:items-start items-center justify-center w-full max-w-3xl 2xl:p-36 xl:p-20 md:p-8 order-first">
            <h1 className="font-medium mb-8 text-4xl text-center w-full">
              Log In
            </h1>

            {query.ref === 'password-reset-success' && (
              <div className="flex items-center p-4 mb-4 rounded bg-green-300 w-full">
                <CheckCircleIcon
                  width={24}
                  height={24}
                  className="mr-1 text-green-800"
                />
                <p className="text-green-800">Your password has been reset. </p>
              </div>
            )}

            <form onSubmit={handleSubmit} className="w-full">
              <section className="mb-4">
                <Label htmlFor="login-email">Email</Label>
                <Input
                  id="login-email"
                  name="email"
                  type="email"
                  required
                  placeholder="example@example.com"
                />
              </section>
              <section className="mb-8">
                <Label htmlFor="login-password">Password</Label>
                <Input
                  id="login-password"
                  name="password"
                  type="password"
                  required
                />
              </section>
              {error && (
                <p className="mb-8 text-red-400 text-center">{error}</p>
              )}
              <section className="flex justify-start">
                {login.status === 'loading' ? (
                  <Spinner />
                ) : (
                  <button className="bg-blue-400 w-full h-12 rounded-sm text-white font-medium leading-[1.1] md:mb-0 md:text-base text-lg">
                    Log In
                  </button>
                )}
              </section>

              <p className="text-xs text-black-300 mt-8 font-bold">
                Your Home Repair Estimate login credentials are separate from
                your Thumbtack credentials.
              </p>
            </form>

            <div className="text-xs text-black-300 w-full mt-4">
              <p>
                New to Repair Estimates?{' '}
                <Link href="/signup">
                  <a className="text-brand">Create an account.</a>
                </Link>{' '}
                <Link href="/reset-password">
                  <a className="text-brand">Forgot Password?</a>
                </Link>
              </p>
            </div>
          </div>

          <img
            src="/images/home/login_graphic.png"
            className="md:inline hidden mb-0 w-1/2 max-w-xl"
            width="50%"
          />
        </div>
      </main>

      <Footer />
    </>
  )
}
